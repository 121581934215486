import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import Form from 'Components/UI/Form'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Breadcrumbs from 'Components/UI/Breadcrumbs'

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.contact = React.createRef()
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Contact'
      }
    ]
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
  }
  onFormSubmit = () => {
    const request = {
      first_name: this.contact.current.refs.firstName.state.value,
      last_name: this.contact.current.refs.lastName.state.value,
      email: this.contact.current.refs.mail.state.value,
      phone: this.contact.current.refs.phoneNumber.state.value,
      subject: 'General',
      message: this.contact.current.refs.message.state.value.replace(/\r?\n/g, '<br/>')
    }
    setTimeout(AppActions.sendContactFormRequest, 0, request)
  }
  render() {
    const customer = AppStore.getCustomer()
    const contactForm = [
      [
        {
          type: 'input',
          props: {
            id: 'firstName',
            className: 'u-col-md-5',
            type: 'text',
            placeholder: 'First Name',
            value: customer ? customer.first_name : '',
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: 'lastName',
            className: 'u-col-md-6 u-offset-md-1',
            type: 'text',
            placeholder: 'Last Name',
            value: customer ? customer.last_name : '',
            required: true
          }
        }
      ],
      {
        type: 'input',
        props: {
          id: 'mail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Enter your mail',
          value: customer ? customer.email : '',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'phoneNumber',
          className: 'u-col-12',
          type: 'tel',
          placeholder: 'Telephone (ex. +XX XX XX XX XX)',
          value: '',
          required: false
        }
      },
      // {
      //   type: 'select',
      //   props: {
      //     id: 'subject',
      //     className: 'u-col-12',
      //     options: [
      //       { value: 'order', text: 'Order' }
      //     ],
      //     placeholder: 'Select a subject',
      //     value: [
      //       { value: 'AF', text: 'Afghanistan' }
      //     ],
      //     required: true
      //   }
      // },
      {
        type: 'textarea',
        props: {
          id: 'message',
          className: 'u-col-12',
          placeholder: 'Write your message..',
          value: '',
          required: true
        }
      }
    ]
    return (
      <Layout>
        <SeoHead
          title="Contact"
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--120' />
        <div className="u-offset-lg-1 u-col-lg-6 u-remove-autofill u-pad-x--lg">
          <Form items={contactForm} submit={this.onFormSubmit} ref={this.contact}>
            <div className='u-height--60'></div>
            <BackgroundBtn
              className='u-col-lg-4'
              text={`Send`}
              type='submit'
            />
          </Form>
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export default ContactPage
